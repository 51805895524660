import { Box, Grid } from "@mui/material";
import btfCardImg from "../../assests/btf.png";
import careerCardImg from "../../assests/career.png";
import familyCardImg from "../../assests/family.png";
import FeatureCard from "../FeatureCard";

const brancdAmbassador = "https://form.jotform.com/242815321031039";
const studentInternUrl = "https://form.jotform.com/242954939417167";
const affiliateUrl = "https://form.jotform.com/242836470276159";

const GripStudioGrid = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#0c0c0c",
                padding: { xs: "5rem 2rem", md: "5rem 4rem" }, // 'xs' is for mobile, 'md' is for desktop
            }}
        >
            <Grid
                container
                spacing={3}
            >
                <FeatureCard
                    title="Become the Face of GripStudio - Apply Now!"
                    description="Join our exclusive Brand Ambassador program for 2025 and represent the future of innovation and style. Let's grow together!"
                    image={btfCardImg}
                    link={brancdAmbassador}
                />
                <FeatureCard
                    title="Kickstart your career with GripStudio!"
                    description="Apply now for the 2025 Student Internship Program and gain hands-on experience in the world of fitness."
                    image={careerCardImg}
                    link={studentInternUrl}
                />
                <FeatureCard
                    title="Join the GripStudio Affiliate Family"
                    description="Become a part of our 2025 success story—apply now to unlock exclusive benefits and exciting opportunities!"
                    image={familyCardImg}
                    link={affiliateUrl}
                    featured
                />
            </Grid>
        </Box>
    );
};

export default GripStudioGrid;
