import gripstudiologo from "../../assests/logo.svg";
import riseAndGrind from "../../assests/rise-and-grind.png";
import "./index.css";
import { Grid, useMediaQuery } from "@mui/material";
import { HeaderChipSelection } from "../HeaderChipSelection/HeaderChipSelection";
import { useTheme } from "@mui/material/styles";
import Swiper  from "../SwiperComponent";

export interface HeaderSectionProps {
  scrolled?: boolean,
  isUsers: boolean;
  setIsUsers: React.Dispatch<React.SetStateAction<boolean>>;
}

function HeaderSection({ isUsers, setIsUsers }: HeaderSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container>
      <Grid item xs={12} className="header-section">
        <Grid container className="header page-section" maxWidth={'90%'} marginInline={'auto'}>
          <Grid item xs={12} sm={12} md={3} lg={3} className={"logo"}>
            <img
              src={gripstudiologo}
              alt="studio-logo"
              className="gripstudioLogo-header"
              loading="lazy"
            />
          </Grid>
          {isMobile ? (
            <HeaderChipSelection isUsers={isUsers} setIsUsers={setIsUsers} />
          ) : (
            <Grid item xs={12} md={9} lg={9}>
              <ul className="header-list">
                <li
                  className={`header-tag ${isUsers ? "active" : ""}`}
                  onClick={() => setIsUsers(true)}
                >
                 Attendees
                </li>
                <li
                  className={`header-tag ${!isUsers ? "active" : ""}`}
                  onClick={() => setIsUsers(false)}
                >
                  Businesses
                </li>
              </ul>
            </Grid>
          )}
        </Grid>
        <Grid className="slider-heading h1">
          The mobile marketplace  <br /> for health and fitness
        </Grid>
        <Grid container justifyContent="center" className="slider-class">
        {!isMobile && <Swiper/>}
          <Grid item className="mobile-image">
            {isUsers ? (
              <img src={riseAndGrind} alt="mobile-1" />
            ) : (
              <img loading="lazy" src={"https://cdn.gripstudio.app/IpadProTrainer.png"} alt="mobile-2" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderSection;
