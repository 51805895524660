import React from "react";
import { Grid, Button } from "@mui/material";
import "./HeaderChipSelection.css";
import { HeaderSectionProps } from "../HeaderSection/index";
import hitIcon from "../../assests/HIIT2.svg";
import lotus from "../../assests/lotus.svg";

export const HeaderChipSelection = ({
  isUsers,
  setIsUsers,
}: HeaderSectionProps) => {
  return (
    <Grid item xs={12} md={9} lg={9} className="header-section-chip">
      <ul className="header-chip">
        <li className={`header-link ${isUsers ? "active" : ""}`}>
          <Button className="users-link" onClick={() => setIsUsers(true)}>
            <img src={hitIcon} alt={"Hit icon"} className="nav-icon" loading="lazy"/>
            &nbsp; Attendees
          </Button>
        </li>
        <li className={`header-link ${!isUsers ? "active" : ""}`}>
          <Button className="users-link" onClick={() => setIsUsers(false)}>
            <img src={lotus} alt={"lotus icon"} className="nav-icon" loading="lazy"/>
            &nbsp; Businesses
          </Button>
        </li>
      </ul>
    </Grid>
  );
};
