import {
  Container,
  Grid,
  Typography,
} from "@mui/material";
import PlaylistCard from "./PlaylistCard";
import "./spotify-index.css";
import { useEffect, useState } from "react";
import axios from "axios";

interface Playlist {
  id: number;
  embedCode: string;
  displayOrderId: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

export default function SpotifyPlayListAndPodcast() {
  const [playLists, setPlayLists] = useState<Playlist[]>([]);

  const fetchPlayList = async() => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/spotify-playlist`);
      let decodedPlaylists = response.data.map((item: Playlist) => ({
        id: item.id,
        embedCode: item.embedCode
          .replace(/&quot;/g, '"')
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&"),
      }))
      setPlayLists(decodedPlaylists);
    }
    catch(error) {
      console.log("Error in fetching spotify playlist", error);
    }
   
  }

  useEffect(() => {
    fetchPlayList();
  },[])

  return (
    <Grid className="gripStudio-wrapper1">
      <Grid>
        <Typography className="heading" variant="h1">
          Featured playlists and podcasts
          <br /> from GripStudio
        </Typography>
        <Typography
          className="heading-para"
          variant="h6"
          sx={{
            textAlign: "center",
            paddingInline: "30rem",
            paddingBlock: "2rem",
            fontSize: "1.125rem",
            whiteSpace: "normal",
            paddingBlockEnd: "0rem",
          }}
        >
          Experience the music that gets you going and podcasts from the best industry experts out there 
        </Typography>
      </Grid>

      <Container maxWidth="xl">
        <Grid className="gripStudio-wrapper" container spacing={5}>
          {playLists.map((playlist) => (
            <Grid className="content-wrapper" xl={3} lg={3} md={3} sm={12} item key={playlist.id}>
              <PlaylistCard>
                <div
                  dangerouslySetInnerHTML={{ __html: playlist.embedCode }}
                />
              </PlaylistCard>
            </Grid>
          ))}
        </Grid>
      </Container>

    </Grid>
  );
}
