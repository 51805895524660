import { Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import styles from "./FeatureCard.module.css";

/**
 * #### FeatureCardProps
 * ```ts
 *  title: string
 *  description: string
 *  image: string
 *  link: string
 *  featured?: boolean
 * ```
 * This interface is used to define the props for the `FeatureCard` component.
 * */
interface FeatureCardProps {
  title: string;
  description: string;
  image: string;
  link: string;
  featured?: boolean;
}


/**
 * #### FeatureCard component
 * See [FeatureCardProps](./index.tsx 'FeatureCardProps') for details.
 * - This component is used to display a card with a title, description, image, and a button.
 * - The card can be styled as a featured card by passing the `featured` prop.
 * - The card has a button that redirects to a specified link.
 * - The card has a background image that can be passed as a prop.
 */
const FeatureCard = ({
  title,
  description,
  image,
  link,
  featured,
}: FeatureCardProps) => {
  return (
    <Grid
      item
      xs={12}
      md={featured ? 12 : 6}
      className={styles.featureCardContainer}
    >
      <Card
        className={`${styles.featureCard} ${featured ? styles.featured : ""}`}
        style={{
          backgroundImage: !featured?
          `linear-gradient(90deg, rgba(0,0,0, 0.8), rgba(0,0,0, 0)), url(${image}), linear-gradient(rgba(0,0,0), rgba(0,0,0))`
          :`url(${image})`,
        backgroundColor: featured?"#75B0AE":""
        }}
        elevation={2}
      >
        <CardContent className={styles.featureCardContent}>
          <Stack
            direction="column"
            spacing={3}
            className={styles.featureCardText}
          >
            <Typography
              variant="h4"
              className={styles.featureCardTitle}
              fontWeight={"bold"}
            >
              {title}
            </Typography>
            <Typography variant="body1" className={styles.featureCardDescription}>
              {description}
            </Typography>
          </Stack>
          <Button
          disableRipple
           className={styles.button} type="submit" href={link}>
            APPLY NOW
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FeatureCard;